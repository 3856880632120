<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col cols="12">
        <!-- Table Container Card -->
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Search -->
              <b-col cols="12" md="12" class="pr-0">
                <div class="d-flex align-items-center justify-content-start">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="filterData.search"
                      class="d-inline-block mr-1 px-0 border-radius-right-0"
                      placeholder="بــــــــــحـــــــــث..."
                      @input="applyFilter"
                    />
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-table
            v-if="getAllClients"
            :items="getAllClients"
            responsive
            :fields="tableColumns"
            :per-page="filterData.per_page"
            primary-key="id"
            show-empty
            empty-text="لا يوجد أي بيانات"
            class="position-relative"
          >
            <!-- Column: name -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="36"
                    v-if="data.item.photo"
                    :src="data.item.photo.full_path + data.item.photo.file_name"
                  />
                  <b-avatar size="36" v-else />
                </template>
                <p class="m-0" dir="ltr">{{ data.value }}</p>
                <small class="text-muted">{{ data.item.role_name }}</small>
              </b-media>
            </template>

            <!-- Column: phone -->
            <template #cell(phone)="data">
              <p class="m-0" dir="ltr">{{ data.value }}</p>
            </template>

            <!-- Column: nationality -->
            <template #cell(nationality)="data">
              <p class="m-0" dir="ltr">{{ data.value ? data.value : "--------" }}</p>
            </template>

            <!-- Column: status -->
            <template #cell(status)="data">
              <b-badge pill variant="light-success" v-if="data.value == 1"> نشط </b-badge>
              <b-badge pill variant="light-warning" v-if="data.value == 2">
                موقف
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap" dir="ltr">
                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="25"
                      class="align-middle text-body"
                      @click="
                        itemClient == null
                          ? (itemClient = data.item)
                          : (itemClient =
                              null || itemClient == data.item
                                ? (itemClient = null)
                                : (itemClient = data.item))
                      "
                    />
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'details-client',
                      params: { id: data.item.id },
                    }"
                  >
                    <span class="align-middle ml-50">عرض التفاصيل</span>
                    <feather-icon size="20" icon="EyeIcon" />
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showStatusClientModal = !showStatusClientModal"
                  >
                    <span class="align-middle ml-50"
                      >{{ data.item.status == 1 ? "ايقاف" : "تنشيط" }}
                    </span>
                    <stop-icon />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <div v-if="getAllClients && getAllClients.length > 0" class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <label>إدخالات</label>
                <v-select
                  v-model="filterData.per_page"
                  :dir="'rtl'"
                  :options="perPageOptions"
                  :clearable="false"
                  :searchable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                  style="width: 15%"
                  @input="handlePerPageChange($event)"
                />
                <span class="text-muted"
                  >عرض {{ 1 }} إلى {{ filterData.per_page }} من
                  {{ getTotalClients.totalItems }} إدخالات</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="filterData.page"
                  :total-rows="getTotalClients.totalItems"
                  :per-page="filterData.per_page"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="handlePageChange"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-if="itemClient"
      :title="itemClient.status == 1 ? 'ايقاف العميل' : 'تنشيط العميل'"
      v-model="showStatusClientModal"
      hide-footer
      centered
    >
      <div class="profile">
        <div class="header-content p-0" style="min-height: auto">
          <div class="header-content-form p-0">
            <b-row class="align-items-center w-100 m-0 text-center">
              <b-col lg="12" class="py-5">
                <h2>
                  {{
                    itemClient.status == 1 ? "تاكيد ايقاف العميل" : "تاكيد تنشيط العميل"
                  }}
                </h2>
                <p>
                  {{
                    itemClient.status == 1
                      ? "لن يتمكن العميل من استخدام الموقع "
                      : "سوف يتمكن العميل من استخدام الموقع "
                  }}
                </p>
              </b-col>
              <b-col
                lg="12"
                class="my-1 px-0 d-lg-flex d-sm-contents justify-content-between"
              >
                <b-button class="filter-main-btn" @click="showStatusClientModal = false">
                  إلـــــــغــــــاء
                </b-button>
                <b-button class="add-main-btn" @click="changeStatusEmployee">
                  {{ itemClient.status == 1 ? "ايقاف" : "تنشيط" }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    vSelect,
  },
  data() {
    return {
      filterData: {
        status: "",
        page: 1,
        per_page: 10,
        order_by: "desc",
        search: "",
      },
      perPageOptions: [10, 25, 50, 100],
      showStatusClientModal: false,
      itemClient: null,
      tableColumns: [
        { key: "id", label: "#", sortable: false },
        { key: "name", label: "اسم العميل", sortable: false },
        { key: "count_bookings", label: "عدد الحجوزات", sortable: false },
        { key: "phone", label: "رقم الهاتف", sortable: false },
        { key: "nationality", label: "الجنسية", sortable: false },
        { key: "status", label: "الحالة", sortable: false },
        { key: "actions", label: " " },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllClients: "getAllClients",
      getTotalClients: "getTotalClients",
    }),
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      loadClients: "loadClients",
      changeClientStatus: "changeClientStatus",
    }),
    addUser() {
      this.$router.push({
        name: "settings-add-user",
      });
    },
    applyFilter() {
      this.loadClients(this.filterData);
    },
    handlePageChange(value) {
      this.filterData.page = value;
      this.loadClients(this.filterData);
    },
    handlePerPageChange(value) {
      this.loadClients(this.filterData);
    },
    changeStatusEmployee() {
      if (this.itemClient) {
        this.changeClientStatus(this.itemClient.id).then((res) => {
          {
            this.itemClient = null;
            this.showStatusClientModal = false;
            this.loadClients(this.filterData);
          }
        });
      }
    },
  },
  mounted() {
    this.loadClients(this.filterData);
  },
};
</script>
